<template>
   <div class="content">
     <div class="row">

 <div class="col-md-12">
<vuestic-widget :headerText="$t('view.deviceevent.title')">
  <p>{{$t('view.deviceevent.description') }}</p>
   <div class="row">

        <div class="col-md-3">
           <button type="button" v-on:click="back" class="btn btn-primary btn-sm"> {{$t('view.deviceevent.back')}}</button>
        </div>

          <div class="col-md-3 offset-md-6">
           <!-- <button type="button" v-on:click="newProductStation" class="btn btn-primary btn-sm">{{$t('view.deviceevent.new')}}</button> -->
        </div>
   </div>
</vuestic-widget>
 </div>
            <div class="col-md-12">
      <TableDeviceEvent></TableDeviceEvent>

        </div>
     </div>
   </div>
</template>

<script>
import Vue from 'vue'
import TableDeviceEvent from '../tables/TableDevice/TableDeviceEvent.vue'

import { SpringSpinner } from 'epic-spinners'

export default {
  name: 'Table',
  components: {
    SpringSpinner,
    TableDeviceEvent
  },
  data () {
    return {
      namestation:null
    }
  },  
  methods: {     
    back () {
       this.$router.go(-1);
     // this.$router.push('/')
    },
    newProductStation () {
      this.$router.push('product/new')
    }

  }
}
</script>

<style lang="scss">

  .color-icon-label-table {
    td:first-child {
      width: 1rem;
    }
  }
</style>
